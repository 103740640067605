import { createApp } from 'vue'
import App from './App.vue'
import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'
import { createRouter, createWebHistory } from 'vue-router'
import TransferForm from "@/components/TransferForm";
import i18n from '@/lang'
import '@vueform/vueform/themes/vueform/css/index.min.css';

const app = createApp(App)
app.use(Vueform, vueformConfig)

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: TransferForm }
        // 這裡可以添加其他路由規則
    ]
})
app.use(router)  // 使用Vue Router


app.use(i18n);

app.mount('#app')
