import { createI18n } from 'vue-i18n'

// 啟用i18N
const i18n = createI18n({
    locale: localStorage.getItem('language') || 'th', // 預設語言
    messages: {
        en: require('./locales/en.json'),
        th: require('./locales/th.json'),
        vn: require('./locales/vn.json'),
    },
    silentTranslationWarn: true
})

export default i18n