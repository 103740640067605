<template>
    {{ $t("ContactUs") }}
    <i class="pi pi-telegram" style="font-size: 2rem;color: #36ACE8" @click="openInNewTab('https://t.me/yaralucki')"></i>
    <br>
    {{ $t("BusinessHours") }}
</template>

<script>
import 'primeicons/primeicons.css'

export default {

  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
  },
};
</script>