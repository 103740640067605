<template>
  <div class="form">
    <Vueform v-model="paymentRequestData" ref="form$">
      <GroupElement name="account" :label="$t('AccountInformation')">
        <select-element
          :native="true"
          name="countryId"
          :items="countries"
          :columns="5"
          default="0"
        ></select-element>
        <TextElement
          name="phoneNumber"
          :placeholder="$t('PhoneNumber')"
          :columns="7"
          rules="required|digits_between:9,10|integer"
        />
        <TextElement name="nickName" :placeholder="$t('NickName')" />
        <TextElement
          name="otpCode"
          :placeholder="$t('OtpCode')"
          rules="required"
        />
      </GroupElement>
      <ButtonElement
        v-if="!accountValid"
        name="submit"
        add-class="mt-2"
        @click="GetPaymentRequest"
      >
        {{ $t("VerifyAccount") }}
      </ButtonElement>

      <GroupElement
        v-if="accountValid"
        name="bank"
        :label="$t('BankInformation')"
      >
        <select-element
          :native="true"
          name="bankId"
          :items="bankList"
        ></select-element>
        <TextElement
          name="bankAccountNumber"
          :placeholder="$t('AccountNumber')"
          rules="required|integer"
        />
        <TextElement
          name="bankAccountName"
          :placeholder="$t('AccountName')"
          rules="required"
        />

        <GroupElement
          name="amount"
          :label="$t('Amount') + ': ' + amount"
          :description="$t('TransferFee')"
        >
        </GroupElement>
      </GroupElement>

      <ButtonElement
        v-if="accountValid"
        name="submit"
        add-class="mt-2"
        @click="submitPaymentRequest"
      >
        {{ $t("Submit") }}
      </ButtonElement>
    </Vueform>
  </div>
</template>
<script>
import axios from "axios";

export default {
  created() {
    this.url = process.env.VUE_APP_APIURL;
  },
  data: () => ({
    accountValid: false,
    paymentRequestData: {
      phoneNumber: "",
      nickName: "",
      otpCode: "",
      bankId: "",
      bankAccountNumber: 0,
      bankAccountName: "",
      countryId:"",
      amount: 0,
    },
    amount: 0,
    bankList: [],
    statusMsg: "",
    showStatusMsg: false,
    countries: [
      { value: 0, label: "ประเทศไทย" },
      { value: 1, label: "Việt Nam" },
      { value: 2, label: "Philippines" },
    ],
    url: "",
  }),
  methods: {
    async getBankId(countryId) {
      const response = await axios.get(this.url + "/api/Payment/GetBankInfo?countryId="+countryId);
      this.bankList = response.data.result.data.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });
    },
    async GetPaymentRequest() {
      try {
        this.showStatusMsg = false;

        const response = await axios.post(
          this.url + "/api/Payment/GetPaymentRequest",
          this.paymentRequestData
        );

        // Check the response status and handle it as needed
        if (response.status === 200) {
          this.$refs.form$.messageBag.clear();

          const paymentInfo = response.data;

          if (paymentInfo.status === 0) {
            this.accountValid = true;
            this.amount = paymentInfo.amount;
            await this.getBankId(this.paymentRequestData.countryId);
          } else {
            this.showStatusMsg = true;
            switch (paymentInfo.status) {
              case 1:
                alert(this.$t("TransactionClaimed"));
                break;
              case 2:
                alert(this.$t("TransactionCompleted"));
                break;
              case 3:
                alert(this.$t("TransactionRejected"));
                break;
              case 4:
                alert(this.$t("TransactionLocked"));
                break;
            }
          }
        } else {
          const msg =
            response.data.ErrorCode === 1001
              ? this.$t("VerificationFailed")
              : response.data.ErrorMsg;
          this.$refs.form$.messageBag.append(msg);
          console.error("An error occurred while submit:", response.data);
        }
      } catch (error) {
        this.$refs.form$.messageBag.clear();
        let errorMsg;
        if (error.response.status === 500) {
          errorMsg =
            error.response.data.ErrorCode === 1001
              ? this.$t("VerificationFailed")
              : error.response.data.ErrorMsg;
        } else {
          errorMsg = this.$t("GeneralError");
        }
        this.$refs.form$.messageBag.append(errorMsg);
        console.error("An error occurred while submit:", error.response.data);
      }
    },
    async submitPaymentRequest() {
      try {

        const response = await axios.post(
          this.url + "/api/Payment/AddPayment",
          this.paymentRequestData
        );

        // Check the response status and handle it as needed
        if (response.status === 200) {
          alert(this.$t("SubmitSuccess"));
          window.location.reload();
        } else {
          alert(this.$t("SubmitFailed"));
          // Handle errors or display error messages to the user.
        }
      } catch (error) {
        alert(this.$t("SubmitFailed"));
        console.error("An error occurred while submit:", error);
        // Handle network errors or other unexpected issues.
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.form {
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #0056b3;
}
</style>
